import React from "react";
import "../loading/loading.css";
/**
 * This component is used before i18Next is initialized so no translation resources are available
 */
export const LoadingWithoutTranslation: React.FunctionComponent = () => {
  return (
    <div className='loading'>
      <div className='loading__spinner'></div>
      <div className='loading__label'>Ładowanie...</div>
    </div>
  );
};

export default LoadingWithoutTranslation;