import { useEffect, useState } from "react";

export type FetchFunction = () => void
export type RetryFunction = () => void
export type UseRequestPollingState = {
    isMaxNumberOfRefetch: boolean;
    retry: RetryFunction
}
export interface UseRequestPollingHookConfig {
    MAX_RETRY: number,
    REFETCH_INTERVAL: number,
}
export type UseRequestPollingHook = (fetch: FetchFunction, config: UseRequestPollingHookConfig) => UseRequestPollingState

export const useRequestPolling: UseRequestPollingHook = (fetch, { MAX_RETRY, REFETCH_INTERVAL }) => {
    const [retryCounter, setRetryCounter] = useState<number>(0)
    const [isMaxNumberOfRefetch, setIsMaxNumberOfRefetch] = useState<boolean>(false)

    useEffect(() => {
        fetch();
    }, [])

    useEffect(() => {
        if (retryCounter > 0 && retryCounter <= MAX_RETRY) {
            setTimeout(() => {
                fetch();
            }, REFETCH_INTERVAL)
        } else if (retryCounter > MAX_RETRY) {
            setIsMaxNumberOfRefetch(true)
        }
    }, [retryCounter])

    const retry = () => {
        setRetryCounter(counter => counter + 1)
    }

    return {
        isMaxNumberOfRefetch,
        retry
    }
};