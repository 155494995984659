import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentMethod } from '../../../services/api/payments/payments.types'
import { TransactionsSummary } from '../../../utils/get-transacions-summary'
import PaymentDetails from '../payment-details/payment-details'
import CardFooter from "../../card/card-footer";
import './hybrid-payment.css';
import cardsLogoBig from "../../../assets/cards@2x.png";
import cardsLogo from "../../../assets/cards.png";
import lockIcon from "../../../assets/lock-closed.svg";
import payULogoBig from "../../../assets/payu@2x.png";
import payULogo from "../../../assets/payu.png";
import infoIcon from "../../../assets/info.svg";

export interface HybridPaymentProps {
    setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>
    transactionsSummary: TransactionsSummary
    payByLink: () => Promise<void>
}

const HybridPayment: React.FC<HybridPaymentProps> = ({ transactionsSummary, setSelectedPaymentMethod, payByLink }) => {
    const { t } = useTranslation()

    const handleCyclicPayment = () => {
        setSelectedPaymentMethod('CYCLIC')
    }

    const handleSinglePayment = async () => {
        await payByLink()
    }

    return (
      <>
        <PaymentDetails transactionsSummary={transactionsSummary} />
        <section className="container card hybrid-payment">
          <h1 className="hybrid-payment__title">{t('Wybierz metodę płatności')}</h1>
          <div className="hybrid-payment__info">
            <img className="hybrid-payment__info__icon" src={infoIcon} alt="info icon" />
            {t('Wybrana metoda będzie domyślna podczas kolejnego okres rozliczeniowego')}
          </div>


          <button onClick={handleCyclicPayment} className="card__item card__item--button">
            <img className="card__item__icon" width="83" height="35" srcSet={`${cardsLogoBig} 2x`} src={cardsLogo} alt="cards logo" />
            <div className="card__item__text">
              <h2 className="card__item__title">{t('Płatność cykliczna kartą')}<img width="18" height="18" src={lockIcon} /></h2>
              <span className="card__item__desc">{t('Każdego miesiąca będziemy potrącali opłatę z Twojej karty')}</span>
            </div>
          </button>

          <button onClick={handleSinglePayment} className="card__item card__item--button">
            <img className="card__item__icon" width="83" height="35" srcSet={`${payULogoBig} 2x`} src={payULogo} alt="PayU logo" />
            <div className="card__item__text">
              <h2 className="card__item__title">{t('Płatność jednorazowa')}</h2>
              <span className="card__item__desc">{t('Przejdź do PayU i wybierz opcję płatności jednorazowej')}</span>
            </div>
          </button>

          <CardFooter />
        </section>
      </>
    )
}

export default HybridPayment