import React, { useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { getPaymentConfigFromParams } from "../../../../utils/get-payment-config-from-params"
import TransactionsAnalyzer from "../transactions-analyzer/transactions-analyzer"
import { PaymentConfigParamsInfo } from "../transactions-analyzer/transactions-analyzer.types"
import ErrorCard from "../../../error-card";
import { useTranslation } from "react-i18next"

const TransactionsParamsAnalyzer: React.FC = () => {
    const [params] = useSearchParams()
    const { t } = useTranslation() 

    const { params: paymentConfig, isParamsValid, errors: paramsErrors } = useMemo<PaymentConfigParamsInfo>(() => {
        return getPaymentConfigFromParams(params)
    }, [params])

    if (!isParamsValid) {
        // TODO: add an error logging mechanism
        return <ErrorCard message={t("Podane parametry transakcji są nieprawidłowe.")}/>;
    }

    return <TransactionsAnalyzer paymentConfig={paymentConfig} />
}

export default TransactionsParamsAnalyzer