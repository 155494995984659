import axios, { AxiosResponse } from 'axios'
import keycloak from '../../../bootstrap/keycloak/keycloak'
import { EmployeeTransactionsIds, LeadingMethodDto, Order, OrderResponse, OrderStatusResponse } from './payments.types'

const baseUrl = `${process.env.REACT_APP_API_GATEWAY_URL}/payments`

const paymentApiDefinition = () => {

    const createOrder = async (order: Order): Promise<OrderResponse> => {
        const response = await axios.post<OrderResponse, AxiosResponse<OrderResponse>, Order>(`${baseUrl}/orders`, order, {
            maxRedirects: 0,
            validateStatus: (status) => (status === 302),
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            }
        })
        return response.data
    }

    const getOrderStatus = async (orderId: string): Promise<OrderStatusResponse> => {
        const response = await axios.get<OrderStatusResponse>(`${baseUrl}/employee-orders/${orderId}`, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            }
        })
        return response.data
    }

    const getLeadingMethod = async (transactionsIds: EmployeeTransactionsIds): Promise<LeadingMethodDto> => {
        const response = await axios.post<LeadingMethodDto, AxiosResponse<LeadingMethodDto>, EmployeeTransactionsIds>(`${baseUrl}/employee-transactions/leading-method`, transactionsIds, {
            headers: {
                Authorization: `Bearer ${keycloak.token}`,
            }
        })
        return response.data
    }


    return {
        createOrder,
        getOrderStatus,
        getLeadingMethod
    }
}

export const paymentApi = paymentApiDefinition()