import { AxiosResponse } from "axios";
import { reportMissingTranslation } from "../../services/api/translations";

const reportedMissingPhrases = new Set();


export const missingKeyHandler = async (
  languages: readonly string[],
  namespace: string,
  key: string
) => {
  const hasOnlyUnsupportedLanguage =
    languages.length === 1 && languages[0] === 'pl';
  if (hasOnlyUnsupportedLanguage) {
    return;
  }

  const translationKey = `${namespace}:::${key}`;
  if (!reportedMissingPhrases.has(translationKey)) {
    reportedMissingPhrases.add(translationKey);

    const promises: Promise<AxiosResponse<void, void>>[] = [];

    for (let i = 0; i < languages.length; i += 1) {
      const language = languages[i];
      const isLanguageSupportedByTranslationService = language !== 'pl';
      if (isLanguageSupportedByTranslationService) {
        try {
          promises.push(reportMissingTranslation({ language, namespace, key }));
        } catch (e) {
          reportedMissingPhrases.delete(translationKey);
        }
      }
    }
    await Promise.all(promises);
  }
};
