import { paymentApi } from "../services/api/payments/payments.api";
import { Order, OrderResponse, Origin } from "../services/api/payments/payments.types";

export const createPayByLinkOrder = async (transactionsIds: string[],  origin: Origin):Promise<OrderResponse> => {
    const order: Order = {
        transactions: transactionsIds,
        type: 'PAY_BY_LINK',
        origin
    }
    return paymentApi.createOrder(order)
}

export const createPayByCardOrder = async (transactionsIds: string[],  origin: Origin, payuToken: string):Promise<OrderResponse> => {
    const order: Order = {
        transactions: transactionsIds,
        type: 'PAY_BY_CARD',
        origin,
        token: payuToken
    }
    return paymentApi.createOrder(order)
}