import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { useKeycloak } from "@react-keycloak/web";

import { AppNotificationContextProvider } from "./context";
import Main from "./components/main";
import { ErrorBoundary } from "./components";
import { Notifier } from "./components";
import NotFound from "./components/not-found";
import TransactionsParamsAnalyzer from "./components/payments/analyzers/transactions-params-analyzer/transactions-params-analyzer";
import FinalizeOrderParamsAnalyzer from "./components/payments/analyzers/finalize-order-params-analyzer/finalize-order-params-analyzer";
import {
  getLanguageFromToken,
  i18nextChangeLanguage,
  initI18n,
  Language,
} from "./bootstrap/i18next/i18next";
import keycloak from "./bootstrap/keycloak/keycloak";
import LoadingWithoutTranslation from "./components/loading-without-translation";
const queryClient = new QueryClient();

export const App = () => {
  const { initialized } = useKeycloak();
  const [isI18NextInitialized, setIsI18NextInitialized] =
    useState<boolean>(false);

  const isAppReady = useMemo<boolean>(
    () => initialized && isI18NextInitialized,
    [initialized, isI18NextInitialized]
  );

  useEffect(() => {
    (async () => {
      if (initialized) {
        await initI18n();
        const { token } = keycloak;
        const language: Language = getLanguageFromToken(token);
        await i18nextChangeLanguage(language);
        setIsI18NextInitialized(true);
      }
    })();
  }, [initialized]);

  return (
    <ErrorBoundary>
      <AppNotificationContextProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            {isAppReady ? (
              <Main>
                <Routes>
                  <Route index element={<TransactionsParamsAnalyzer />} />
                  <Route
                    path={"finalize-order"}
                    element={<FinalizeOrderParamsAnalyzer />}
                  />
                  <Route path={"*"} element={<NotFound />} />
                </Routes>
              </Main>
            ) : (
              <LoadingWithoutTranslation />
            )}
          </BrowserRouter>
        </QueryClientProvider>
        <Notifier />
      </AppNotificationContextProvider>
    </ErrorBoundary>
  );
};
