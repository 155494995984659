import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { missingKeyHandler } from "./i18next.utils";
import keycloak from "../keycloak/keycloak";
import jwtDecode from "jwt-decode";
import { AccessToken } from "../../models";
import i18next from "i18next";


export type Language = 'pl' | 'en'

const UI_PAYMENTS_GATEWAY_INTERFACE_NAMESPACE = "ui-payments-gateway-interface";
export const defaultLanguage: Language = 'pl'

export const initI18n = () => {
  return i18next
    .use(initReactI18next)
    .use(HttpApi)
    .init({
      backend: {
        loadPath: process.env.REACT_APP_API_GATEWAY_URL + "/translations/{{lng}}/{{ns}}",
        allowMultiLoading: false,
        customHeaders: () => {
          return {
            authorization: `Bearer ${keycloak.token}`,
          };
        },
      },
      ns: [UI_PAYMENTS_GATEWAY_INTERFACE_NAMESPACE],
      defaultNS: UI_PAYMENTS_GATEWAY_INTERFACE_NAMESPACE,
      lng: "pl",
      fallbackLng: "pl",
      interpolation: {
        escapeValue: false,
      },
      saveMissing: true,
      saveMissingTo: "all",
      missingKeyHandler,
    });
}

export const getLanguageFromToken = (token: string): Language => {
  const decodedToken = jwtDecode(token) as AccessToken;
  const language: Language | undefined = (decodedToken?.locale || decodedToken?.preferred_language) as Language;
  return language || 'pl'
}

export const i18nextChangeLanguage = async (language: Language) => {
 return i18next.changeLanguage(language);
}