import React, { ReactNode, useState } from "react";

// TODO export this type from common-components
type NotificationItem = {
  id: string | number;
  type: "error" | "warning" | "success";
  text: string;
};

const DEFAULT_NOTIFICATION_CLEAR_TIMEOUT = 3000;

export const AppNotificationContext = React.createContext(null);

export function AppNotificationContextProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const [notificationList, setNotificationList] = useState(null);

  return (
    <AppNotificationContext.Provider
      value={{
        notificationList,
        notify: (notifications: NotificationItem[]) => {
          setNotificationList(notifications);
          setTimeout(() => {
            setNotificationList(null);
          }, DEFAULT_NOTIFICATION_CLEAR_TIMEOUT);
        },
      }}
    >
      {children}
    </AppNotificationContext.Provider>
  );
}
