import axios from "axios";
import keycloak from "../../../bootstrap/keycloak/keycloak";

type GetByPhraseParams = {
  language: string;
  namespace: string;
  key: string;
};

export const reportMissingTranslation = async ({
  language,
  namespace,
  key,
}: GetByPhraseParams) => {
  return await axios.post(
    process.env.REACT_APP_API_GATEWAY_URL +
      `/translations/missing/${language}/${namespace}`,
    { key },
    {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    }
  );
};
