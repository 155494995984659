import React from "react";
import { useTranslation } from "react-i18next";
import "./loading.css";
export const Loading: React.FunctionComponent = () => {
  const { t } = useTranslation()
  
  return (
    <div className='loading'>
      <div className='loading__spinner'></div>
      <div className='loading__label'>{t('Ładowanie')}...</div>
    </div>
  );
};

export default Loading;