import React from 'react'
import { PaymentMethod } from '../../../services/api/payments/payments.types'
import { TransactionsSummary } from '../../../utils/get-transacions-summary'
import CardForm from '../card-form/card-form'
import PaymentDetails from '../payment-details/payment-details'

export interface CyclicPaymentsProps {
    transactionsSummary: TransactionsSummary,
    payByCard: (payuToken: string) => Promise<void>,
    setSelectedPaymentMethod?: React.Dispatch<React.SetStateAction<PaymentMethod>>
}

const CyclicPayments: React.FC<CyclicPaymentsProps> = ({ transactionsSummary, payByCard, setSelectedPaymentMethod }) => {
    return (
        <div className="wrapper">
            <PaymentDetails transactionsSummary={transactionsSummary} />
            <CardForm payByCard={payByCard} totalAmount={transactionsSummary.totalAmount} setSelectedPaymentMethod={setSelectedPaymentMethod} />
        </div>

    )
}

export default CyclicPayments