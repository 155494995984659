import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import keycloak from "./bootstrap/keycloak/keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Suspense fallback={<div />}>
    <ReactKeycloakProvider
      initOptions={{
        onLoad: "login-required",
        pkceMethod: "S256",
      }}
      authClient={keycloak}
    >
      <App />
    </ReactKeycloakProvider>
  </Suspense>
);