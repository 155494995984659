import React from 'react';
import {useTranslation} from "react-i18next";
import CardFooter from '../card/card-footer';
import errorIcon from '../../assets/error.svg';
import './error-card.css';
import { useSearchParams } from 'react-router-dom';

const FALLBACK_URI = 'fallbackRedirectUri'
interface ErrorCardProps {
  message?: string;
  withFallback?: boolean;
  hasFooter?: boolean;
}
const ErrorCard: React.FC<ErrorCardProps> = ({message, withFallback = true, hasFooter = true}) => {
  const { t } = useTranslation();
  const [params] = useSearchParams()

  const fallback: string = params.get(FALLBACK_URI)

  return (
    <div className="container card error-card">
      <div className="error-card__icon">
        <img width="28" height="28" src={errorIcon} alt="error icon" />
      </div>
      <span className="error-card__message" dangerouslySetInnerHTML={{__html: message}} />
      {withFallback && Boolean(fallback) && <a href={fallback} className="error-card__fallback">{t('Wróć do Dashboardu')}</a>}
      {hasFooter && <CardFooter />}
    </div>);
}

export default ErrorCard;