import React from 'react';
import { useTranslation } from 'react-i18next';
import './not-found.css';

const NotFound: React.FC = () => {
    const {t} = useTranslation()
    return (<div className="not-found">
        {t('Nie możemy znaleźć strony której szukasz.')}
    </div>);
}

export default NotFound;