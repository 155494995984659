import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getOrderFromParams } from "../../../../utils/get-order-from-params";
import ErrorCard from "../../../error-card";
import OrderAnalyzer from '../finalize-order-analyzer/finalize-order-analyzer';
import { OrderParamsInfo } from "../finalize-order-analyzer/finalize-order-analyzer.types";

const FinalizeOrderParamsAnalyzer: React.FC = () => {
    const { t } = useTranslation()
    const [params] = useSearchParams()

    const { params: orderConfig, isParamsValid, errors: paramsErrors } = useMemo<OrderParamsInfo>(() => {
        return getOrderFromParams(params)
    }, [params])

    if (!isParamsValid) {
        // TODO: add an error logging mechanism
        return <ErrorCard message={t("Podane parametry zamówienia są nieprawidłowe.")} />;
    }

    return <OrderAnalyzer orderConfig={orderConfig} />
}

export default FinalizeOrderParamsAnalyzer;