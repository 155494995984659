import React from "react";
import {Header} from "../header";
import "./main.css";
import "../card/card.css";

interface MainProps {
  children?: React.ReactNode;
}

export const Main: React.FunctionComponent<MainProps> = ({children}) => {
  return (
    <div className='main'>
      <Header />
      <div className="wrapper">
        {children}
      </div>
    </div>
  );
}

export default Main;