import { EmployeeTransaction, Product } from "../services/api/payments/payments.types";

export interface TransactionsSummary {
    products: Product[],
    transactionsIds: string[]
    totalAmount: number;
}

export const getTransactionsSummary = (transactions: EmployeeTransaction[]): TransactionsSummary => {
    const products: Product[] = transactions.map(({products}) => products).flat()
    const totalAmount: number = transactions.reduce((collector, {totalAmount}) => collector +totalAmount, 0)
    const transactionsIds: string[] = transactions.map(({id}) => id)
    return {
        products,
        totalAmount,
        transactionsIds
    } 
}