import React from 'react'
import { useTranslation } from 'react-i18next'
import { TransactionsSummary } from '../../../utils/get-transacions-summary'
import './payment-details.css'

export interface PaymentDetailsProps {
    transactionsSummary: TransactionsSummary
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({transactionsSummary: {
    products, totalAmount
}}) => {

    const {t} = useTranslation()
    
    return (
      <div className="column">
        <section className=" card payment-details">
          <h3 className="payment-details__title">{t('Płatności')}</h3>
          <ul className="payment-details__list">
            {products.map(product => (<li className="payment-details__item" key={product.name}>
              <span className="payment-details__label">{product.name}</span>
              <span className="payment-details__value">{`${product.amount} ${t('zł')}`}</span>
            </li>))}
          </ul>
          <div className="payment-details__summary">
            <span className="payment-details__label payment-details__label--summary">{t('Do zapłacenia')}</span>
            <span className="payment-details__value payment-details__value--summary">{`${totalAmount} ${t('zł')}`}</span>
          </div>
        </section>
      </div>
    )
}

export default PaymentDetails