import Keycloak from 'keycloak-js';
import { KeycloakInitOptions } from 'keycloak-js'
import { getLanguageFromToken, i18nextChangeLanguage, initI18n, Language } from '../i18next/i18next';

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
})

export const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: 'login-required',
  pkceMethod: 'S256'
}

export default keycloak;