import { OrderParamsInfo } from "../components/payments/analyzers/finalize-order-analyzer/finalize-order-analyzer.types";



export const getOrderFromParams = (params: URLSearchParams): OrderParamsInfo => {
    const orderId: string = params.get('orderId')
   
    const isOrderIdValid: boolean = orderId?.length > 0;
  
    let errors: Record<string, string> = {}

    if (!isOrderIdValid) {
        errors.orderIdMissed = 'Search param OrderId is required'
    }

    return {
        params: {
            orderId
        },
        isParamsValid: isOrderIdValid,
        errors
    }
}