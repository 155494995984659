import { ErrorResponse } from '@remix-run/router'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequestPolling} from '../../../../hooks/useRequestPolling/useRequestPolling'
import { paymentApi } from '../../../../services/api/payments/payments.api'
import { OrderStatusResponse } from '../../../../services/api/payments/payments.types'
import ErrorCard from '../../../error-card'
import Loading from '../../../loading'
import { OrderConfigParams } from './finalize-order-analyzer.types'

const MAX_RETRY_TO_GET_ORDER_STATUS = Number(process.env.REACT_APP_MAX_RETRY_TO_GET_ORDER_STATUS) || 10
const REFETCH_INTERVAL_TO_GET_ORDER_STATUS = Number(process.env.REACT_APP_REFETCH_INTERVAL_TO_GET_ORDER_STATUS) || 1000

export interface FinalizeOrderAnalyzerProps {
    orderConfig: OrderConfigParams
}

const FinalizeOrderAnalyzer: React.FC<FinalizeOrderAnalyzerProps> = ({ orderConfig: { orderId } }) => {
    const { t } = useTranslation()

    const { data, isError: isResponseError, error: responseError, refetch } = useQuery<OrderStatusResponse, ErrorResponse>({
        retry: 1,
        enabled: false,
        queryKey: ['getOrderStatus'],
        queryFn: () =>
            paymentApi.getOrderStatus(orderId),
        initialData: {
            status: 'PENDING'
        },
        onSuccess(data) {
            const { status } = data;
            if (status === 'PENDING') {
                retry()
            } else if (status === 'SUCCESS') {
                location.href = data.redirectUriSuccess
            } else {
                location.href = data.redirectUriFailure
            }
        }
    })

    const { isMaxNumberOfRefetch, retry } = useRequestPolling(refetch, {
        MAX_RETRY: MAX_RETRY_TO_GET_ORDER_STATUS,
        REFETCH_INTERVAL: REFETCH_INTERVAL_TO_GET_ORDER_STATUS
    })

    useEffect(() => {
        if (isMaxNumberOfRefetch) {
            tryToLoadErrorUrl()
        }
    }, [isMaxNumberOfRefetch])


    const tryToLoadErrorUrl = () => {
        if (data?.redirectUriFailure) {
            location.href = data.redirectUriFailure
        }
    }

    if (isResponseError) {
        // TODO: add an error logging mechanism

        const messageContent: string[] = [
            t('Ups! Coś poszło nie tak po naszej stronie.'),
            t('Odśwież stronę i spróbuj ponownie.')
        ]
        const messageToShow: string = messageContent.join('<br />')
        return <ErrorCard message={messageToShow} />;
    }

    if (isMaxNumberOfRefetch) {
        const messageContent: string[] = [
            t('Ups! Nie jesteśmy wstanie obecnie zweryfikować statusu Twojego zamówienia.'),
            t('Odśwież stronę i spróbuj ponownie.')
        ]
        const messageToShow: string = messageContent.join('<br />')
        return <ErrorCard message={messageToShow} />
    }

    return (
        <>
            {!isMaxNumberOfRefetch && <Loading />}
        </>
    )
}

export default FinalizeOrderAnalyzer