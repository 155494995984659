import React from "react";
import logo from "../../assets/logo-benefit.svg";
import "./header.css";
import {LanguageSwitcher} from "../language-switcher";

export const Header: React.FunctionComponent = () => {
  return (
    <header className='header'>
      <div className='header__wrapper'>
        <img src={logo} className='header__logo' />
        <LanguageSwitcher />
      </div>
    </header>
  );
};
