import React from "react";
import { useTranslation } from "react-i18next";

const CardFooter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="card__footer">
      {t('Dokonując płatności akceptujesz')} <a href="#regulation" target="_blank">{t('regulamin BenefitSystems')}</a>.
    </div>
  );
};

export default CardFooter;